import React from "react";
import CSSModules from "react-css-modules";
import styles from "./App.module.css";
import Home from "../Home/Home";

function App() {
  return (
    <div className={styles.appContainer}>
      <Home />
    </div>
  );
}

export default CSSModules(App, styles, { allowMultiple: true });
