import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: [
      "Noto Sans:300,400,500,700",
      "Open Sans:300,400,500,700",
      "Lora:300,400,500,700",
      "Roboto Mono:300,400,500,700",
      "Lato:300,400,500,700",
      "Poppins:300,400,500,700",
      "Rubik:300,400,500,700",
      "sans-serif"
    ]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
