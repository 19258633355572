import React from "react";
import CSSModules from "react-css-modules";
import styles from "./Home.module.css";
import me from "./me.png";

function Home() {
  return (
    <div className={styles.total}>
      <div className={styles.me}>
        <img className={styles.picture} src={me} alt="Isaac Walker" />
        <div className={styles.info}>
          <span className={styles.name}>Isaac Walker</span>
          <span className={styles.job}>Backend Engineer at Apple</span>
          <div className={styles.links}>
            <a
              className={styles.link}
              href="https://www.linkedin.com/in/walker-i/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              className={styles.link}
              href="resume.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSSModules(Home, styles, { allowMultiple: true });
